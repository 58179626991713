<template>
    <div class="bg-white px-3 pb-3">
		<template v-for="(item,itemI) in Object.keys(translated.en)">
			<a-form-item :key="itemI + 'lang'" :label="item.charAt(0).toUpperCase() + item.slice(1)" class="mb-1">
				<a-input v-model="translated.en[item]" />
			</a-form-item>            
		</template>
    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    props:['value'],
    components:{ImageBoxSelector},
    data:() => ({
        translated:{},
        currentStep:0,
    }),
    computed:{
        instance(){
            return this.$store.state.instance
        },
    },
    methods:{
        update(){
            this.value = {...this.translated}
        }
    },
	watch: {
		translated: {
			handler(val, oldVal) {
				console.log('WATCH RAN', val)
				return this.$emit('input', val)
			},
			deep: true
		}
	},
    created(){
		let translated = JSON.parse(JSON.stringify(this.value))
		this.translated = translated
		console.log('TRANSLATIONS', translated)
    }
}
</script>
